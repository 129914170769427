import React, {useState} from "react";
import TopNavigation, { TopNavigationProps} from "@amzn/awsui-components-react/polaris/top-navigation";
import {useTranslation} from "react-i18next";
import { getTimezoneNameFromUrl, getValueFromSearchParam, urlUpdater } from "../utils/url";
import { useSearchParams } from "react-router-dom";
import {allTimezoneWithOffsetOptions} from "../data/timezoneOptions";
import { useUser } from "../hooks/user"
import { useFeatures, featureIsEnabled } from "../hooks/features";

import { externalLinks } from "../data/externalLinks";
import { publishMetric, publishMetricsWithAttributes} from "./analytics";
import { publishKatalMetric } from "./katalAnalytics";
import { useSpaWrapper } from "./SpaLink";

export function TopNav() {
  const spaWrapper = useSpaWrapper();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedTimezone, setSelectedTimezone] = useState(getTimezoneNameFromUrl(getValueFromSearchParam(searchParams, "timezone")));
  const updateUrlValue = urlUpdater(setSearchParams);

  const { userId } = useUser();
  const features = useFeatures();

  const defaultUtilites: TopNavigationProps.Utility[] = [
    {
      type: "menu-dropdown",
      text: selectedTimezone,
      items: allTimezoneWithOffsetOptions,
      onItemClick: (event) => {
        const timezone = getTimezoneNameFromUrl(event.detail.id)
        setSelectedTimezone(timezone);
        publishMetricsWithAttributes("timeZoneSelector", "timezone", timezone);
        publishKatalMetric("timeZoneSelector", timezone);
        updateUrlValue(["timezone", event.detail.id || defaultTimezone]);
      }
    },
    {
      type: "button",
      text: t("topnav.feedback"),
      variant: "link",
      href: externalLinks.support.feedback,
      target: "_blank"
    },
    {
      type: "button",
      text: t("topnav.contact"),
      variant: "link",
      href: externalLinks.support.contact,
      target: "_blank"
    }
  ]
  const benevityButBetterUtilities: TopNavigationProps.Utility[] = [
    {
      type: "button",
      text: t("topnav.search"),
      variant: "link",
      href: "/",
      onFollow: spaWrapper({
        onFollow: () => {
          publishMetric("nav_search");
          publishKatalMetric("ButtonClick", "nav_search");
        }
      })
    },
    {
      type: "menu-dropdown",          
      text: t("topnav.resources"),
      onItemFollow: ({ detail }) => {
        publishMetric("nav_" + detail.id);
        publishKatalMetric("ButtonClick", "nav_" + detail.id);
      },
      items: [
        {
          id: "vcWiki",
          text: t("footer.vcWiki"),
          href: externalLinks.support.vcWiki,
          external: true,
        },
        {
          id: "aciWiki",
          text: t("footer.aciWiki"),
          href: externalLinks.support.aciWiki,
          external: true,
        },
        {
          id: "planningPlaybooks",
          text: t("topnav.planningPlaybooks"),
          href: externalLinks.support.planningPlaybooks,
          external: true,
        },
        {
          id: "training",
          text: t("topnav.training"),
          href: externalLinks.support.training,
          external: true,
        }
      ]
    },
    {
      type: "button",
      text: userId + "@",
      variant: "link"
    }
  ]
  
  if(featureIsEnabled("Hiring", features, searchParams)) {
    const hiringOption: TopNavigationProps.Utility = {
      type: "button",
      text: t("topnav.hiring"),
      variant: "link",
      href: externalLinks.support.hiring,
      target: "_blank",
      iconName:"external",
      //there is no good way to css select a specific button in the title of topnav
      //unless you use the aria label. And you don't think to hard about the meaning of the word "good"
      //https://issues.amazon.com/issues/cce-4026
      ariaLabel: t("topnav.hiring"),
    }
    
    // Add to front of array of options so it is closer to the middle of the toolbar
    // (Since options are right-aligned)
    defaultUtilites.unshift(hiringOption);
    benevityButBetterUtilities.unshift(hiringOption);
  }

  return (
    <TopNavigation
      identity={{
        href: "/",
        //annoyingly something in cloudscape fubars the onFollow event 
        //and removes the href. TLDR: you can't use spaWrapper because it doesn't know where
        //to navigate because there is no event.detail.href
        onFollow: spaWrapper({ url: "/" }),
        logo: {
          src: "/red-velvet-logo.svg",
          alt: t('programName')
        }
      }}
      utilities={featureIsEnabled("BenevityButBetter", features, searchParams) ? benevityButBetterUtilities : defaultUtilites}
    />
  );
}
