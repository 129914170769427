import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query'

// This code resets the queries to their initial unloaded state when the page 
// path changes (ie when moving from search page to event page)
export function ResetQueriesOnPageChange() {
    const location = useLocation();
    const queryClient = useQueryClient();
    // Using the prevLocation in state ensures that we only reset queries when the page
    // changes, and not on a re-render
    const [prevLocation, setPrevLocation] = useState(location.pathname);
    
    // Even though this resets more often than needed, we need it to run on inital variables
    // being set, or else the cached data in the query client ends up being displayed
    // on the initial page load and then refreshed in the background.
    useEffect(() => {
        if(prevLocation !== location.pathname) {
            // We are using resetQueries instead of invalidate queries for accessibility.
            // resetQueries does a hard flush of the cache, and returns all the queries to
            // an unloaded state, so they have to be refetched and will show a loading state
            // while fetching
            queryClient.resetQueries();
            setPrevLocation(location.pathname);
        }
    }, [location.pathname, prevLocation, setPrevLocation, queryClient]);
    
    return <></>;
}
